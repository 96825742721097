import React from 'react'
import ReactMarkdown from "react-markdown"
// import { MDXRenderer } from "gatsby-plugin-mdx"
import { graphql, Link } from 'gatsby'
// import BackgroundImage from "gatsby-background-image"
import TopMenu from '../components/topMenu'
import SEO from "../components/seo"
import Footer from "../components/footer"
import slugHandler from '../api/slugHandlerBrowser'
// import Rupor from '../components/view/rupor'
import Drawing from '../components/view/drawing'
import PlainIndex from '../components/view/plain-index'

const NewsTemplate = ({ data, pageContext }) => {
    // console.log("AlbumsTemplate: ", data);
  const { frontmatter: content } = data.content
  const slug = content.slug;
  const albums = data.albums.nodes;
  return (
    <section className={"page vp-lang-" + pageContext.language}>
      <SEO
        title="Home"
        description={content.description}
      />
      <TopMenu smallLogo={true} lang={content.language} languages={pageContext.languages} slug={slug} translation={pageContext.translation}></TopMenu>
      <div className="container-1200">
        <div className="section vp-albums-in">

        <div className="vp-section-header vp-section-header--reverse columns aic mb4">
          <div className="column column-50 posr mr2">
            <h1 className="color-accent-3">{content.title}</h1>
            <ReactMarkdown source={content.content} linkTarget="_blank"/>
          </div>
          <div className="column column-50">
              {/* <div className="vp-news-rupor color-accent-3">
              <Drawing direction="top-right" immediate={true} >
                <Rupor/>
              </Drawing>
              </div> */}
              <div className="vp-news-drawing__plain1 color-accent-1">
                <Drawing immediate><PlainIndex /></Drawing>
              </div>
          </div>
        </div>
          <div className="vp-albums-list tac">
            {
              albums.map((album)=>{
                const slug = album.slug.split('.')[0];
                const path = slugHandler(
                  content.language,
                  slug
                )
                
                return (
                    <div key={album.id} className="vp-album-item">
                      <Link to={path} className="">
                          <img src={album.frontmatter.image} alt={album.frontmatter.title} loading="lazy" className="vp-album-item__pic mb1"/>
                          <h4 className="mb0">{album.frontmatter.title}</h4>
                          <div className="fz18 fw300">{album.frontmatter.date}</div>
                      </Link>
                    </div>
                )
              })
            }
          </div>
        </div>
      </div>
      <Footer lang={content.language} translation={pageContext.translation}></Footer>
    </section>
  )
}

export default NewsTemplate

export const indexQuery = graphql`
  query NewsById($id: String!, $language: String!, $langSlugRegEx: String!) {
    content: mdx(
      id: { eq: $id },
      frontmatter: {
        type: { eq: 0 }
        templateKey: { eq: "templates/news" }
        language: { eq: $language }
      }
    ) {
      frontmatter {
        slug
        language
        title
        description
        content
      }
    }
    albums: allMdx(filter: {frontmatter: {templateKey: {eq: "album"}, tags: {regex: "/news/"}}, slug: {regex: $langSlugRegEx }}, sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        slug
        id
        frontmatter {
          date(formatString: "DD MMM YYYY")
          description
          title
          image
        }
      }
    }
    
  }
`