import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import React, { useEffect, useRef, useState } from "react"
import Pencil from "./pencil";
import "./drawing.css"

function Drawing(props){
    const drawingRef = useRef(null);
    const [isDone, setDone] = useState(false);
    const [isAnimated, setAnimated] = useState(false);
    // const [isAnimating, setAnimating] = useState(false);

    useScrollPosition(
        ({ currPos }) => {
            if (!isDone) {
                if (currPos.y > 0 && ((window.innerHeight || document.documentElement.clientHeight) - currPos.y) > 0){
                    // console.log("visible");
                    doDraw();
                }
                // console.log("done:", isDone, "drawing: ", currPos);
            }
        },
        [isDone],
        drawingRef,
        false,
        300
      );

      useEffect(() => {
            if (props.immediate && !isDone) doDraw();
      });

    // const drawingClick = () => {
    //     console.log("drawingClick()");
    // }

    const doDraw = () => {
        setDone(true);
        
        if (props.delay){
            setTimeout(()=>setAnimated(true), Number(props.delay));
        } 
        else {
            setAnimated(true);
        }

      }

    let classes = "posr vp-drawing" + (isAnimated ? " vp-animate" : "");

    return (
        <div ref={drawingRef} className={classes}>
            <Pencil direction={props.direction}></Pencil>
            <div className="vp-drawing-subject">{ props.children }</div>
        </div>
    );
}

export default Drawing